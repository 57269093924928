body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#play-icon {
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  width: 120px;
  height: 120px;
  fill: white;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.5516s;
}

#play-icon.fade-out {
  opacity: 0;
}